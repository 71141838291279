import request from "src/lib/axios/request";
import { IApiResponse } from "src/utils/models/common";
import {
  IContinuouslyAbsentRecord,
  IDashboardReportPayload,
  IDashboardStudentListRecord,
  IDateFilter,
  ISearchCancelOrTerminateStudentData,
  IsearchGetStudent,
  ILowPerformance,
  IBatchAndStudentCount,
} from "./studentList.model";
import config from "src/utils/config";

class StudentListService {
  ENDPOINT = config.baseApiBatch + "/student-list";

  public searchStudentListDataById = async (data: {
    batch_id: string | number | URLSearchParams;
  }): Promise<IApiResponse<IsearchGetStudent>> => {
    const url = `${this.ENDPOINT}/${data.batch_id}/student-details`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };

  public batchStudentsListDataById = async (data: {
    batchIds: string;
  }): Promise<IApiResponse<IsearchGetStudent>> => {
    const url = `${this.ENDPOINT}/batches-students-list`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };

  public dashboardStudentListData = async (
    data: IDashboardReportPayload | undefined | URLSearchParams
  ): Promise<IApiResponse<IDashboardStudentListRecord>> => {
    const url = `${this.ENDPOINT}/student-report`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };

  public studentMarksPending = async (
    data: string[] | undefined | URLSearchParams | IDateFilter
  ): Promise<IApiResponse<IsearchGetStudent>> => {
    const url = `${this.ENDPOINT}/student-marks-pending`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };
  public overDueBatchData = async (
    data: string[] | undefined | URLSearchParams | IDateFilter
  ): Promise<IApiResponse<IsearchGetStudent>> => {
    const url = `${this.ENDPOINT}/overDue-batch`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };

  public studentAbsentReport = async (
    data: string | URLSearchParams
  ): Promise<IApiResponse<IsearchGetStudent>> => {
    const url = `${this.ENDPOINT}/today-student-absent-report`;
    return request({ url, method: "GET" }).then((res) => {
      return res.data;
    });
  };

  public studentLowAttendanceReport = async (
    data: string | URLSearchParams | IDateFilter
  ): Promise<IApiResponse<IsearchGetStudent>> => {
    const url = `${this.ENDPOINT}/student-low-attendance-report`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };

  public updateBatchStudentsMarksModify = async (
    data: any
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/student-marks`;
    return request({ url, method: "PUT", data: data }).then((res) => {
      return res.data;
    });
  };

  public cancelOrTerminateStudent = async (
    data: string | URLSearchParams | IDateFilter
  ): Promise<IApiResponse<ISearchCancelOrTerminateStudentData[]>> => {
    const url = `${this.ENDPOINT}/student-attended-lecture`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };

  public searchStudentDetailsData = async (
    data: string | number | URLSearchParams
  ): Promise<IApiResponse<IsearchGetStudent>> => {
    const url = `${this.ENDPOINT}/student-details`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };

  public studentPerformance = async (
    admission_id: number
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/${admission_id}/student-performance`;
    return request({ url, method: "GET", params: admission_id }).then((res) => {
      return res.data;
    });
  };

  public studentAttendance = async (
    admission_id: number
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/${admission_id}/student-attendance`;
    return request({ url, method: "GET", params: admission_id }).then((res) => {
      return res.data;
    });
  };

  public continuouslyAbsent = async (
    data: IContinuouslyAbsentRecord | URLSearchParams | {}
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/continuously-absent`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };

  public studentLowperformanceReport = async (
    data: ILowPerformance | URLSearchParams | {}
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/below-eighty-percentage-student-performance-report`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };

  public facultyWiseBatchAndStudentCount = async (
    data: IBatchAndStudentCount | URLSearchParams | {}
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/faculty-wise-batch-and-student-count`;
    return request({ url, method: "GET", params: data }).then((res) => {
      return res.data;
    });
  };

  public todayAbsentIsRemarksAdded = async (
    data: any
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/today-absent-is-remarks-added`;
    return request({ url, method: "PUT", data: data }).then((res) => {
      return res.data;
    });
  };
}

export default new StudentListService();
