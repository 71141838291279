import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app.model";
import {
  saveStudentPtmReport,
  getAdmissionRecurringData,
  getPtmAdmissionRecurringData,
  getAdmissionRecurringDataByAdmissionId,
  createAdmissionRecurring,
  searchStudentsData,
  getStudentPtmReportById,
  saveCompanyVisitData,
  getCompanyVisitData,
  updateExpertSessionData,
  saveExpertSessionData,
  getExpertSessionData,
  getCompanyVisitById,
  updateCompanyVisit,
  ptmStudentReportEmailSend,
  updateAdmissionRecuringsData,
  getInterviewSessionData,
  saveInterviewSessionData,
  updateInterviewSessionData,
  studentSurveyBatchWiseData,
  missingAdmissionReportStaticsData,
  getCvAdmissionRecurringData,
  getEsAdmissionRecurringData,
  getPisAdmissionRecurringData,
} from "./students.action";
import { IStudentsState } from "./students.model";
import {
  Discipline_type,
  PTM_STUDENT_REPORT_STATUS_TYPES,
  Relation_type,
  Uniform_type,
} from "src/utils/constants/constant";

export const initialState: IStudentsState = {
  searchData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      rows: [],
      meta: {
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
    },
  },
  admissionRecurringData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      rows: [],
      meta: {
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
    },
  },
  ptmAdmissionRecurringData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      rows: [],
      meta: {
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
    },
  },
  cvAdmissionRecurringData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      rows: [],
      meta: {
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
    },
  },
  esAdmissionRecurringData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      rows: [],
      meta: {
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
    },
  },
  pisAdmissionRecurringData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      rows: [],
      meta: {
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
    },
  },
  admissionRecurring: {
    loading: false,
    hasErrors: false,
    message: "",
    data: null,
  },
  createAdmissionRecurring: {
    loading: false,
    hasErrors: false,
    message: "",
    data: null,
  },
  updateAdmissionRecuringsData: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  companyVisitData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: { rows: [] },
  },
  expertSessionData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: { rows: [] },
  },

  updateExpertSession: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  saveExpertSession: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  interviewSessionData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: { rows: [] },
  },

  updateInterviewSession: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  saveInterviewSession: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  saveStudentPtmReport: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  getCompanyVisitById: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      company_name: "",
      branch_id: 0,
      company_address: "",
      visit_datetime: "",
      details: "",
      execute_by_user_id: 0,
      status: "",
      cvVolunteers: [],
      executeByUser: {
        id: 0,
        first_name: "",
        last_name: "",
      },
    },
  },
  updateCompanyVisit: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  saveCompanyVisit: {
    loading: false,
    hasErrors: false,
    message: "",
  },

  getStudentPtmReportById: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      admission: {},
      admission_id: 0,
      attendance_days: null,
      behaviour_with_faculty: 0,
      behaviour_with_management: 0,
      behaviour_with_student: 0,
      completed_course: "",
      discipline: Discipline_type.GOOD,
      id: null,
      relation_with_student: Relation_type.FATHER,
      remark: "",
      running_course: "",
      status: PTM_STUDENT_REPORT_STATUS_TYPES.PENDING,
      total_days: null,
      uniform: Uniform_type.REGULAR,
      visiting_datetime: "",
      visiting_person: "",
      visitor_mobile_no: "",
      visitor_remark: "",
      work_submission: "",
      batch_wise_attendance_details: [],
    },
  },

  ptmStudentReportEmailSend: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  studentSurveyBatchWise: {
    loading: false,
    hasErrors: false,
    message: "",
    data: { rows: [] },
  },
  missingAdmissionReportStaticsData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: [
      {
        branch_id: 0,
        branch_code: "",
        totalAdmissionCount: 0,
        missingCount: 0,
        completedCount: 0,
        CategoryWiseAdmissionCount: [],
      },
    ],
  },
};

export const studentsSlice = createSlice({
  name: "students",
  initialState,
  reducers: {
    clearRemoveMessage: (state) => {
      state.admissionRecurringData.message = "";
      state.ptmAdmissionRecurringData.message = "";
      state.cvAdmissionRecurringData.message = "";
      state.esAdmissionRecurringData.message = "";
      state.pisAdmissionRecurringData.message = "";
      state.admissionRecurring.message = "";
      state.getStudentPtmReportById.message = "";
      state.saveStudentPtmReport.message = "";
      state.searchData.message = "";
      state.studentSurveyBatchWise.message = "";
      state.ptmStudentReportEmailSend.message = "";
      state.companyVisitData.message = "";
      state.updateExpertSession.message = "";
      state.saveExpertSession.message = "";
      state.updateCompanyVisit.message = "";
      state.saveCompanyVisit.message = "";
      state.updateAdmissionRecuringsData.message = "";
      state.interviewSessionData.message = "";
      state.updateInterviewSession.message = "";
      state.saveInterviewSession.message = "";
    },
  },
  extraReducers: {
    // search students Data
    [searchStudentsData.pending.type]: (state) => {
      state.searchData.loading = true;
    },
    [searchStudentsData.fulfilled.type]: (state, action) => {
      state.searchData.data = action.payload.data;
      state.searchData.message = action.payload.message;
      state.searchData.loading = false;
      state.searchData.hasErrors = false;
    },
    [searchStudentsData.rejected.type]: (state, action) => {
      state.searchData.loading = false;
      state.searchData.hasErrors = true;
      state.searchData.message = action.error.message;
    },
    [studentSurveyBatchWiseData.pending.type]: (state) => {
      state.studentSurveyBatchWise.loading = true;
    },
    [studentSurveyBatchWiseData.fulfilled.type]: (state, action) => {
      state.studentSurveyBatchWise.data = action.payload.data;
      state.studentSurveyBatchWise.message = action.payload.message;
      state.studentSurveyBatchWise.loading = false;
      state.studentSurveyBatchWise.hasErrors = false;
    },
    [studentSurveyBatchWiseData.rejected.type]: (state, action) => {
      state.studentSurveyBatchWise.loading = false;
      state.studentSurveyBatchWise.hasErrors = true;
      state.studentSurveyBatchWise.message = action.error.message;
    },

    // get Admission recurring data
    [getAdmissionRecurringData.pending.type]: (state) => {
      state.admissionRecurringData.loading = true;
    },
    [getAdmissionRecurringData.fulfilled.type]: (state, action) => {
      state.admissionRecurringData.data = action.payload.data;
      state.admissionRecurringData.message = action.payload.message;
      state.admissionRecurringData.loading = false;
      state.admissionRecurringData.hasErrors = false;
    },
    [getAdmissionRecurringData.rejected.type]: (state, action) => {
      state.admissionRecurringData.loading = false;
      state.admissionRecurringData.hasErrors = true;
      state.admissionRecurringData.message = action.error.message;
    },

    // get PTM Admission recurring data
    [getPtmAdmissionRecurringData.pending.type]: (state) => {
      state.ptmAdmissionRecurringData.loading = true;
    },
    [getPtmAdmissionRecurringData.fulfilled.type]: (state, action) => {
      state.ptmAdmissionRecurringData.data = action.payload.data;
      state.ptmAdmissionRecurringData.message = action.payload.message;
      state.ptmAdmissionRecurringData.loading = false;
      state.ptmAdmissionRecurringData.hasErrors = false;
    },
    [getPtmAdmissionRecurringData.rejected.type]: (state, action) => {
      state.ptmAdmissionRecurringData.loading = false;
      state.ptmAdmissionRecurringData.hasErrors = true;
      state.ptmAdmissionRecurringData.message = action.error.message;
    },

    // get CV Admission recurring data
    [getCvAdmissionRecurringData.pending.type]: (state) => {
      state.cvAdmissionRecurringData.loading = true;
    },
    [getCvAdmissionRecurringData.fulfilled.type]: (state, action) => {
      state.cvAdmissionRecurringData.data = action.payload.data;
      state.cvAdmissionRecurringData.message = action.payload.message;
      state.cvAdmissionRecurringData.loading = false;
      state.cvAdmissionRecurringData.hasErrors = false;
    },
    [getCvAdmissionRecurringData.rejected.type]: (state, action) => {
      state.cvAdmissionRecurringData.loading = false;
      state.cvAdmissionRecurringData.hasErrors = true;
      state.cvAdmissionRecurringData.message = action.error.message;
    },

    // get ES Admission recurring data
    [getEsAdmissionRecurringData.pending.type]: (state) => {
      state.esAdmissionRecurringData.loading = true;
    },
    [getEsAdmissionRecurringData.fulfilled.type]: (state, action) => {
      state.esAdmissionRecurringData.data = action.payload.data;
      state.esAdmissionRecurringData.message = action.payload.message;
      state.esAdmissionRecurringData.loading = false;
      state.esAdmissionRecurringData.hasErrors = false;
    },
    [getEsAdmissionRecurringData.rejected.type]: (state, action) => {
      state.esAdmissionRecurringData.loading = false;
      state.esAdmissionRecurringData.hasErrors = true;
      state.esAdmissionRecurringData.message = action.error.message;
    },

    // get PIS Admission recurring data
    [getPisAdmissionRecurringData.pending.type]: (state) => {
      state.pisAdmissionRecurringData.loading = true;
    },
    [getPisAdmissionRecurringData.fulfilled.type]: (state, action) => {
      state.pisAdmissionRecurringData.data = action.payload.data;
      state.pisAdmissionRecurringData.message = action.payload.message;
      state.pisAdmissionRecurringData.loading = false;
      state.pisAdmissionRecurringData.hasErrors = false;
    },
    [getPisAdmissionRecurringData.rejected.type]: (state, action) => {
      state.pisAdmissionRecurringData.loading = false;
      state.pisAdmissionRecurringData.hasErrors = true;
      state.pisAdmissionRecurringData.message = action.error.message;
    },

    // get Admission recurring data by Admission Id
    [getAdmissionRecurringDataByAdmissionId.pending.type]: (state) => {
      state.admissionRecurring.loading = true;
    },
    [getAdmissionRecurringDataByAdmissionId.fulfilled.type]: (
      state,
      action
    ) => {
      state.admissionRecurring.data = action.payload.data;
      state.admissionRecurring.message = action.payload.message;
      state.admissionRecurring.loading = false;
      state.admissionRecurring.hasErrors = false;
    },
    [getAdmissionRecurringDataByAdmissionId.rejected.type]: (state, action) => {
      state.admissionRecurring.loading = false;
      state.admissionRecurring.hasErrors = true;
      state.admissionRecurring.message = action.error.message;
    },

    // crate Admission recurring
    [createAdmissionRecurring.pending.type]: (state) => {
      state.admissionRecurring.loading = true;
    },
    [createAdmissionRecurring.fulfilled.type]: (state, action) => {
      state.admissionRecurring.data = action.payload.data;
      state.admissionRecurring.message = action.payload.message;
      state.admissionRecurring.loading = false;
      state.admissionRecurring.hasErrors = false;
    },
    [createAdmissionRecurring.rejected.type]: (state, action) => {
      state.admissionRecurring.loading = false;
      state.admissionRecurring.hasErrors = true;
      state.admissionRecurring.message = action.error.message;
    },

    //get company visit data
    [getCompanyVisitData.pending.type]: (state) => {
      state.companyVisitData.loading = true;
    },
    [getCompanyVisitData.fulfilled.type]: (state, action) => {
      state.companyVisitData.data = action.payload.data;
      state.companyVisitData.message = action.payload.message;
      state.companyVisitData.loading = false;
      state.companyVisitData.hasErrors = false;
    },
    [getCompanyVisitData.rejected.type]: (state, action) => {
      state.companyVisitData.loading = false;
      state.companyVisitData.hasErrors = true;
      state.companyVisitData.message = action.error.message;
    },
    //get expertSession planning data

    [getExpertSessionData.pending.type]: (state) => {
      state.expertSessionData.loading = true;
    },
    [getExpertSessionData.fulfilled.type]: (state, action) => {
      state.expertSessionData.data = action.payload.data;
      state.expertSessionData.message = action.payload.message;
      state.expertSessionData.loading = false;
      state.expertSessionData.hasErrors = false;
    },
    [getExpertSessionData.rejected.type]: (state, action) => {
      state.expertSessionData.loading = false;
      state.expertSessionData.hasErrors = true;
      state.expertSessionData.message = action.error.message;
    },
    //get interview planning data

    [getInterviewSessionData.pending.type]: (state) => {
      state.interviewSessionData.loading = true;
    },
    [getInterviewSessionData.fulfilled.type]: (state, action) => {
      state.interviewSessionData.data = action.payload.data;
      state.interviewSessionData.message = action.payload.message;
      state.interviewSessionData.loading = false;
      state.interviewSessionData.hasErrors = false;
    },
    [getInterviewSessionData.rejected.type]: (state, action) => {
      state.interviewSessionData.loading = false;
      state.interviewSessionData.hasErrors = true;
      state.interviewSessionData.message = action.error.message;
    },
    //get company visit data by Id

    [getCompanyVisitById.pending.type]: (state) => {
      state.getCompanyVisitById.loading = true;
    },
    [getCompanyVisitById.fulfilled.type]: (state, action) => {
      state.getCompanyVisitById.data = action.payload.data;
      state.getCompanyVisitById.message = action.payload.message;
      state.getCompanyVisitById.loading = false;
      state.getCompanyVisitById.hasErrors = false;
    },
    [getCompanyVisitById.rejected.type]: (state, action) => {
      state.getCompanyVisitById.loading = false;
      state.getCompanyVisitById.hasErrors = true;
      state.getCompanyVisitById.message = action.error.message;
    },

    // save student ptm report
    [saveStudentPtmReport.pending.type]: (state) => {
      state.saveStudentPtmReport.loading = true;
    },
    [saveStudentPtmReport.fulfilled.type]: (state, action) => {
      state.saveStudentPtmReport.message = action.payload.message;
      state.saveStudentPtmReport.loading = false;
      state.saveStudentPtmReport.hasErrors = false;
    },
    [saveStudentPtmReport.rejected.type]: (state, action) => {
      state.saveStudentPtmReport.loading = false;
      state.saveStudentPtmReport.hasErrors = true;
      state.saveStudentPtmReport.message = action.error.message;
    },
    //save company visit list

    [saveCompanyVisitData.pending.type]: (state) => {
      state.saveCompanyVisit.loading = true;
    },
    [saveCompanyVisitData.fulfilled.type]: (state, action) => {
      state.saveCompanyVisit.message = action.payload.message;
      state.saveCompanyVisit.loading = false;
      state.saveCompanyVisit.hasErrors = false;
    },
    [saveCompanyVisitData.rejected.type]: (state, action) => {
      state.saveCompanyVisit.loading = false;
      state.saveCompanyVisit.hasErrors = true;
      state.saveCompanyVisit.message = action.error.message;
    },

    //save expertSession  list

    [saveExpertSessionData.pending.type]: (state) => {
      state.saveExpertSession.loading = true;
    },
    [saveExpertSessionData.fulfilled.type]: (state, action) => {
      state.saveExpertSession.message = action.payload.message;
      state.saveExpertSession.loading = false;
      state.saveExpertSession.hasErrors = false;
    },
    [saveExpertSessionData.rejected.type]: (state, action) => {
      state.saveExpertSession.loading = false;
      state.saveExpertSession.hasErrors = true;
      state.saveExpertSession.message = action.error.message;
    },

    //save interview session  list

    [saveInterviewSessionData.pending.type]: (state) => {
      state.saveInterviewSession.loading = true;
    },
    [saveInterviewSessionData.fulfilled.type]: (state, action) => {
      state.saveInterviewSession.message = action.payload.message;
      state.saveInterviewSession.loading = false;
      state.saveInterviewSession.hasErrors = false;
    },
    [saveInterviewSessionData.rejected.type]: (state, action) => {
      state.saveInterviewSession.loading = false;
      state.saveInterviewSession.hasErrors = true;
      state.saveInterviewSession.message = action.error.message;
    },
    // get student ptm report data by id
    [getStudentPtmReportById.pending.type]: (state) => {
      state.getStudentPtmReportById.loading = true;
    },
    [getStudentPtmReportById.fulfilled.type]: (state, action) => {
      state.getStudentPtmReportById.data = action.payload.data;
      state.getStudentPtmReportById.message = action.payload.message;
      state.getStudentPtmReportById.loading = false;
      state.getStudentPtmReportById.hasErrors = false;
    },
    [getStudentPtmReportById.rejected.type]: (state, action) => {
      state.getStudentPtmReportById.loading = false;
      state.getStudentPtmReportById.hasErrors = true;
      state.getStudentPtmReportById.message = action.error.message;
    },
    //update the cv planning  Data

    [updateCompanyVisit.pending.type]: (state) => {
      state.updateCompanyVisit.loading = true;
    },
    [updateCompanyVisit.fulfilled.type]: (state, action) => {
      state.updateCompanyVisit.message = action.payload.message;
      state.updateCompanyVisit.loading = false;
      state.updateCompanyVisit.hasErrors = false;
    },
    [updateCompanyVisit.rejected.type]: (state, action) => {
      state.updateCompanyVisit.loading = false;
      state.updateCompanyVisit.hasErrors = true;
      state.updateCompanyVisit.message = action.error.message;
    },

    //  update AdmissionRecuringsData for student attendace
    [updateAdmissionRecuringsData.pending.type]: (state) => {
      state.updateAdmissionRecuringsData.loading = true;
    },
    [updateAdmissionRecuringsData.fulfilled.type]: (state, action) => {
      state.updateAdmissionRecuringsData.message = action.payload.message;
      state.updateAdmissionRecuringsData.loading = false;
      state.updateAdmissionRecuringsData.hasErrors = false;
    },
    [updateAdmissionRecuringsData.rejected.type]: (state, action) => {
      state.updateAdmissionRecuringsData.loading = false;
      state.updateAdmissionRecuringsData.hasErrors = true;
      state.updateAdmissionRecuringsData.message = action.error.message;
    },
    //update expertSession
    [updateExpertSessionData.pending.type]: (state) => {
      state.updateExpertSession.loading = true;
    },
    [updateExpertSessionData.fulfilled.type]: (state, action) => {
      state.updateExpertSession.message = action.payload.message;
      state.updateExpertSession.loading = false;
      state.updateExpertSession.hasErrors = false;
    },
    [updateExpertSessionData.rejected.type]: (state, action) => {
      state.updateExpertSession.loading = false;
      state.updateExpertSession.hasErrors = true;
      state.updateExpertSession.message = action.error.message;
    },
    //update interview session
    [updateInterviewSessionData.pending.type]: (state) => {
      state.updateInterviewSession.loading = true;
    },
    [updateInterviewSessionData.fulfilled.type]: (state, action) => {
      state.updateInterviewSession.message = action.payload.message;
      state.updateInterviewSession.loading = false;
      state.updateInterviewSession.hasErrors = false;
    },
    [updateInterviewSessionData.rejected.type]: (state, action) => {
      state.updateInterviewSession.loading = false;
      state.updateInterviewSession.hasErrors = true;
      state.updateInterviewSession.message = action.error.message;
    },
    //PTM Student Report Email Send

    [ptmStudentReportEmailSend.pending.type]: (state) => {
      state.ptmStudentReportEmailSend.loading = true;
    },
    [ptmStudentReportEmailSend.fulfilled.type]: (state, action) => {
      state.ptmStudentReportEmailSend.message = action.payload.message;
      state.ptmStudentReportEmailSend.loading = false;
      state.ptmStudentReportEmailSend.hasErrors = false;
    },
    [ptmStudentReportEmailSend.rejected.type]: (state, action) => {
      state.ptmStudentReportEmailSend.loading = false;
      state.ptmStudentReportEmailSend.hasErrors = true;
      state.ptmStudentReportEmailSend.message = action.error.message;
    },

    // Missing Admission Report Statics Data

    [missingAdmissionReportStaticsData.pending.type]: (state) => {
      state.missingAdmissionReportStaticsData.loading = true;
    },
    [missingAdmissionReportStaticsData.fulfilled.type]: (state, action) => {
      state.missingAdmissionReportStaticsData.data = action.payload.data;
      state.missingAdmissionReportStaticsData.message = action.payload.message;
      state.missingAdmissionReportStaticsData.loading = false;
      state.missingAdmissionReportStaticsData.hasErrors = false;
    },
    [missingAdmissionReportStaticsData.rejected.type]: (state, action) => {
      state.missingAdmissionReportStaticsData.loading = false;
      state.missingAdmissionReportStaticsData.hasErrors = true;
      state.missingAdmissionReportStaticsData.message = action.error.message;
    },
  },
});

// A Selector
export const studentsSelector = (state: RootState) => state.students;

export const { clearRemoveMessage } = studentsSlice.actions;

export default studentsSlice.reducer;
