import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  IAdmissionRecurringData,
  ISaveStudentPtmReportPayload,
  IStudents,
  ISaveCompanyVisitPayload,
  IUpdateCvData,
  IUpdateAdmissionRecuringData,
  PtmStudentReportEmailSendResponse,
  ISaveExpertSessionPayload,
  ISaveInterviewSessionPayload,
  IMissingDetailsParams,
  IAdmissionRecurringCreate,
} from "src/services/students/student.model";
import studentsService from "src/services/students/students.service";

export const searchStudentsData = createAsyncThunk(
  "students/searchStudentData",
  async (data: IStudents | URLSearchParams | {}) => {
    return studentsService.searchStudentsData(data);
  }
);
export const studentSurveyBatchWiseData = createAsyncThunk(
  "students/studentSurveyBatchWiseData",
  async (data: URLSearchParams | {}) => {
    return studentsService.studentSurveyBatchWiseData(data);
  }
);

export const getAdmissionRecurringData = createAsyncThunk(
  "students/getAdmissionRecurringData",
  async (data: IAdmissionRecurringData | URLSearchParams | {}) => {
    return studentsService.getAdmissionRecurringData(data);
  }
);

export const getPtmAdmissionRecurringData = createAsyncThunk(
  "students/getPtmAdmissionRecurringData",
  async (data: IAdmissionRecurringData | URLSearchParams | {}) => {
    return studentsService.getAdmissionRecurringData({...data, type: "PTM"});
  }
);

export const getCvAdmissionRecurringData = createAsyncThunk(
  "students/getCvAdmissionRecurringData",
  async (data: IAdmissionRecurringData | URLSearchParams | {}) => {
    return studentsService.getAdmissionRecurringData({...data, type: "CV"});
  }
);

export const getEsAdmissionRecurringData = createAsyncThunk(
  "students/getEsAdmissionRecurringData",
  async (data: IAdmissionRecurringData | URLSearchParams | {}) => {
    return studentsService.getAdmissionRecurringData({...data, type: "ES"});
  }
);

export const getPisAdmissionRecurringData = createAsyncThunk(
  "students/getPisAdmissionRecurringData",
  async (data: IAdmissionRecurringData | URLSearchParams | {}) => {
    return studentsService.getAdmissionRecurringData({...data, type: "PIS"});
  }
);

export const getAdmissionRecurringDataByAdmissionId = createAsyncThunk(
  "students/getAdmissionRecurringDataByAdmissionId",
  async (admission_id: number) => {
    return studentsService.getAdmissionRecurringDataByAdmissionId(admission_id);
  }
);

export const createAdmissionRecurring = createAsyncThunk(
  "students/createAdmissionRecurring",
  async (data: IAdmissionRecurringCreate) => {
    return studentsService.createAdmissionRecurring(data);
  }
);

export const saveCompanyVisitData = createAsyncThunk(
  "students/saveCompanyVisitData",
  async (data: ISaveCompanyVisitPayload) => {
    return studentsService.saveCompanyVisitData(data);
  }
);
export const saveExpertSessionData = createAsyncThunk(
  "students/saveExpertSessionData",
  async (data: ISaveExpertSessionPayload) => {
    return studentsService.saveExpertSessionData(data);
  }
);

export const updateExpertSessionData = createAsyncThunk(
  "students/updateExpertSessionData",
  async (data: ISaveExpertSessionPayload) => {
    return studentsService.updateExpertSessionData(data);
  }
);

export const getExpertSessionData = createAsyncThunk(
  "students/getExpertSessionData",
  async () => {
    return studentsService.getExpertSessionData();
  }
);

export const saveInterviewSessionData = createAsyncThunk(
  "students/saveInterviewSessionData",
  async (data: ISaveInterviewSessionPayload) => {
    return studentsService.saveInterviewSessionData(data);
  }
);

export const updateInterviewSessionData = createAsyncThunk(
  "students/updateInterviewSessionData",
  async (data: ISaveInterviewSessionPayload) => {
    return studentsService.updateInterviewSessionData(data);
  }
);

export const getInterviewSessionData = createAsyncThunk(
  "students/getInterviewSessionData",
  async () => {
    return studentsService.getInterviewSessionData();
  }
);
export const getCompanyVisitData = createAsyncThunk(
  "students/getCompanyVisitData",
  async () => {
    return studentsService.getCompanyVisitData();
  }
);
export const getCompanyVisitById = createAsyncThunk(
  "students/getCompanyVisitById",
  async (id: number) => {
    return studentsService.getCompanyVisitById(id);
  }
);
export const updateCompanyVisit = createAsyncThunk(
  "students/updateCompanyVisit",
  async (data: IUpdateCvData) => {
    return studentsService.updateCompanyVisit(data);
  }
);

export const saveStudentPtmReport = createAsyncThunk(
  "students/saveStudentPtmReport",
  async (data: ISaveStudentPtmReportPayload) => {
    return studentsService.saveStudentPtmReport(data);
  }
);

export const getStudentPtmReportById = createAsyncThunk(
  "students/getStudentPtmReportById",
  async (id: number) => {
    return studentsService.getStudentPtmReportById(id);
  }
);

export const updateAdmissionRecuringsData = createAsyncThunk(
  "students/updateAdmissionRecuringsData",
  async (data: IUpdateAdmissionRecuringData) => {
    return studentsService.updateAdmissionRecuringsData(data);
  }
);

export const ptmStudentReportEmailSend = createAsyncThunk(
  "students/ptmStudentReportEmailSend",
  async (data: PtmStudentReportEmailSendResponse) => {
    return studentsService.ptmStudentReportEmailSend(data);
  }
);

export const missingAdmissionReportStaticsData = createAsyncThunk(
  "students/missingAdmissionReportStaticsData",
  async (data: IMissingDetailsParams) => {
    return studentsService.missingAdmissionReportStaticsData(data);
  }
);
