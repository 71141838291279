import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../app.model";
import {
  dashboardStudentReport,
  searchStudentsListData,
  batchStudentsListData,
  studentAbsentReport,
  studentLowAttendanceReport,
  updateBatchStudentsMarksModify,
  studentMarksPending,
  overDueBatchData,
  searchStudentDetailsData,
  studentPerformance,
  studentAttendance,
  continuouslyAbsent,
  cancelOrTerminateStudent,
  studentLowperformanceReport,
  facultyWiseBatchAndStudentCount,
  todayAbsentIsRemarksAdded,
} from "./studentList.action";
import { IStudentListState } from "./studentList.model";
import { IApiResponse } from "src/utils/models/common";
import { IBatchesStudentsListRecord } from "src/services/studentList/studentList.model";

export const initialState: IStudentListState = {
  searchData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      rows: [],
      meta: {
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
    },
  },
  overDueBatchData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      count: 0,
      rows: [],
    },
  },
  studentReport: {
    loading: false,
    hasErrors: false,
    message: "",
    data: [
      {
        ongoingBatchCount: 0,
        totalstudentscount: "",
        totalbatchescount: "",
        totalcdfeedbackcount: "",
        totalautofeedbackcount: "",
        todayOngoingStudentsCount: 0,
        todayCAndDFeedBackCount: 0,
        todayAutoFeedBackCount: 0,
        todayPresentAbsentCount: {
          todayPresentCount: 0,
          todayAbsentCount: 0,
        },
        rows: [],
      },
    ],
  },

  studentPendingMarks: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      rows: [],
      count: 0,
    },
  },
  absentreport: {
    loading: false,
    hasErrors: false,
    message: "",
    data: [],
  },
  lowAttendanceReport: {
    loading: false,
    hasErrors: false,
    message: "",
    data: [],
  },
  cancelOrTerminateStudentData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: [],
  },
  updateBatchStudentsMarksModify: {
    loading: false,
    hasErrors: false,
    message: "",
    data: [{ id: 0, marks: 0 }],
  },
  searchStudentDetailsData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      rows: [],
      meta: {
        take: 0,
        itemCount: 0,
        pageCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
    },
  },
  studentPerformanceData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      rows: [],
      count: 0,
    },
  },
  studentAttendanceData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      rows: [],
      count: 0,
    },
  },
  continuouslyAbsent: {
    loading: false,
    hasErrors: false,
    message: "",
    data: [],
  },
  studentLowPerformanceData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: [],
  },
  batchAndStudentCount: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      FacultyWiseData: [],
      fullTimeCount: 0,
      partTimeCount: 0,
      totalBatch: 0,
      totalStudent: 0,
      totalfaculty: 0,
      visitorCount: 0,
    },
  },
  todayAbsentIsRemarksAdded: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {},
  },
  batchStudentListData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: [],
  },
};

export const studentListSlice = createSlice({
  name: "studentList",
  initialState,
  reducers: {
    clearRemoveMessage: (state) => {
      state.updateBatchStudentsMarksModify.message = "";
      state.searchStudentDetailsData.message = "";
    },
  },

  extraReducers: {
    // search studentList Data
    [searchStudentsListData.pending.type]: (state) => {
      state.searchData.loading = true;
    },
    [searchStudentsListData.fulfilled.type]: (
      state,
      action: PayloadAction<IApiResponse<IStudentListState>>
    ) => {
      state.searchData.loading = false;
      state.searchData.hasErrors = false;
      state.searchData.message = action.payload.message;
    },
    [searchStudentsListData.rejected.type]: (state, action) => {
      state.searchData.loading = false;
      state.searchData.hasErrors = true;
      state.searchData.message = action.error.message;
    },

    // search Batches studentList Data
    [batchStudentsListData.pending.type]: (state) => {
      state.batchStudentListData.loading = true;
    },
    [batchStudentsListData.fulfilled.type]: (
      state,
      action: PayloadAction<IApiResponse<IBatchesStudentsListRecord[]>>
    ) => {
      state.batchStudentListData.data = action.payload.data;
      state.batchStudentListData.loading = false;
      state.batchStudentListData.hasErrors = false;
      state.batchStudentListData.message = action.payload.message;
    },
    [batchStudentsListData.rejected.type]: (state, action) => {
      state.batchStudentListData.loading = false;
      state.batchStudentListData.hasErrors = true;
      state.batchStudentListData.message = action.error.message;
    },

    // search studentList Data
    [studentAbsentReport.pending.type]: (state) => {
      state.absentreport.loading = true;
    },
    [studentAbsentReport.fulfilled.type]: (state, action) => {
      state.absentreport.data = action.payload.data;
      state.absentreport.message = action.payload.message;
      state.absentreport.loading = false;
      state.absentreport.hasErrors = false;
    },
    [studentAbsentReport.rejected.type]: (state, action) => {
      state.absentreport.loading = false;
      state.absentreport.hasErrors = true;
      state.absentreport.message = action.error.message;
    },
    //studentPendingMarks  studentMarksPending
    [studentMarksPending.pending.type]: (state) => {
      state.studentPendingMarks.loading = true;
    },
    [studentMarksPending.fulfilled.type]: (state, action) => {
      state.studentPendingMarks.data = action.payload.data;
      state.studentPendingMarks.message = action.payload.message;
      state.studentPendingMarks.loading = false;
      state.studentPendingMarks.hasErrors = false;
    },
    [studentMarksPending.rejected.type]: (state, action) => {
      state.studentPendingMarks.loading = false;
      state.studentPendingMarks.hasErrors = true;
      state.studentPendingMarks.message = action.error.message;
    },
    //StudentReportList
    [dashboardStudentReport.pending.type]: (state) => {
      state.studentReport.loading = true;
    },
    [dashboardStudentReport.fulfilled.type]: (state, action) => {
      state.studentReport.data = action.payload.data;
      state.studentReport.message = action.payload.message;
      state.studentReport.loading = false;
      state.studentReport.hasErrors = false;
    },
    [dashboardStudentReport.rejected.type]: (state, action) => {
      state.studentReport.loading = false;
      state.studentReport.hasErrors = true;
      state.studentReport.message = action.error.message;
    },

    //overDueBatchData overDueBatchData

    [overDueBatchData.pending.type]: (state) => {
      state.overDueBatchData.loading = true;
    },
    [overDueBatchData.fulfilled.type]: (state, action) => {
      state.overDueBatchData.data = action.payload.data;
      state.overDueBatchData.message = action.payload.message;
      state.overDueBatchData.loading = false;
      state.overDueBatchData.hasErrors = false;
    },
    [overDueBatchData.rejected.type]: (state, action) => {
      state.overDueBatchData.loading = false;
      state.overDueBatchData.hasErrors = true;
      state.overDueBatchData.message = action.error.message;
    },

    //studentLowAttendanceReport
    [studentLowAttendanceReport.pending.type]: (state) => {
      state.lowAttendanceReport.loading = true;
    },
    [studentLowAttendanceReport.fulfilled.type]: (state, action) => {
      state.lowAttendanceReport.data = action.payload.data;
      state.lowAttendanceReport.message = action.payload.message;
      state.lowAttendanceReport.loading = false;
      state.lowAttendanceReport.hasErrors = false;
    },
    [studentLowAttendanceReport.rejected.type]: (state, action) => {
      state.lowAttendanceReport.loading = false;
      state.lowAttendanceReport.hasErrors = true;
      state.lowAttendanceReport.message = action.error.message;
    },

    //CancelOrTerminateStudent
    [cancelOrTerminateStudent.pending.type]: (state) => {
      state.cancelOrTerminateStudentData.loading = true;
    },
    [cancelOrTerminateStudent.fulfilled.type]: (state, action) => {
      state.cancelOrTerminateStudentData.data = action.payload.data;
      state.cancelOrTerminateStudentData.message = action.payload.message;
      state.cancelOrTerminateStudentData.loading = false;
      state.cancelOrTerminateStudentData.hasErrors = false;
    },
    [cancelOrTerminateStudent.rejected.type]: (state, action) => {
      state.cancelOrTerminateStudentData.loading = false;
      state.cancelOrTerminateStudentData.hasErrors = true;
      state.cancelOrTerminateStudentData.message = action.error.message;
    },

    //updateBatchStudentsMarksModify
    [updateBatchStudentsMarksModify.pending.type]: (state) => {
      state.updateBatchStudentsMarksModify.loading = true;
    },
    [updateBatchStudentsMarksModify.fulfilled.type]: (state, action) => {
      state.updateBatchStudentsMarksModify.message = action.payload.message;
      state.updateBatchStudentsMarksModify.hasErrors = action.payload.error;
      state.updateBatchStudentsMarksModify.loading = false;
      state.updateBatchStudentsMarksModify.hasErrors = false;
    },
    [updateBatchStudentsMarksModify.rejected.type]: (state, action) => {
      state.updateBatchStudentsMarksModify.loading = false;
      state.updateBatchStudentsMarksModify.hasErrors = true;
      state.updateBatchStudentsMarksModify.message = action.error.message;
    },

    // get student details
    [searchStudentDetailsData.pending.type]: (state) => {
      state.searchStudentDetailsData.loading = true;
    },
    [searchStudentDetailsData.fulfilled.type]: (state, action) => {
      state.searchStudentDetailsData.data = action.payload.data;
      state.searchStudentDetailsData.message = action.payload.message;
      state.searchStudentDetailsData.loading = false;
      state.searchStudentDetailsData.hasErrors = false;
    },
    [searchStudentDetailsData.rejected.type]: (state, action) => {
      state.searchStudentDetailsData.loading = false;
      state.searchStudentDetailsData.hasErrors = true;
      state.searchStudentDetailsData.message = action.error.message;
    },

    // Student performance data get
    [studentPerformance.pending.type]: (state) => {
      state.studentPerformanceData.loading = true;
    },
    [studentPerformance.fulfilled.type]: (state, action) => {
      state.studentPerformanceData.data = action.payload.data;
      state.studentPerformanceData.message = action.payload.message;
      state.studentPerformanceData.loading = false;
      state.studentPerformanceData.hasErrors = false;
    },
    [studentPerformance.rejected.type]: (state, action) => {
      state.studentPerformanceData.loading = false;
      state.studentPerformanceData.hasErrors = true;
      state.studentPerformanceData.message = action.error.message;
    },

    // Student attendance data get
    [studentAttendance.pending.type]: (state) => {
      state.studentAttendanceData.loading = true;
    },
    [studentAttendance.fulfilled.type]: (state, action) => {
      state.studentAttendanceData.data = action.payload.data;
      state.studentAttendanceData.message = action.payload.message;
      state.studentAttendanceData.loading = false;
      state.studentAttendanceData.hasErrors = false;
    },
    [studentAttendance.rejected.type]: (state, action) => {
      state.studentAttendanceData.loading = false;
      state.studentAttendanceData.hasErrors = true;
      state.studentAttendanceData.message = action.error.message;
    },

    // continuously absent data
    [continuouslyAbsent.pending.type]: (state) => {
      state.continuouslyAbsent.loading = true;
    },
    [continuouslyAbsent.fulfilled.type]: (state, action) => {
      state.continuouslyAbsent.data = action.payload.data;
      state.continuouslyAbsent.message = action.payload.message;
      state.continuouslyAbsent.loading = false;
      state.continuouslyAbsent.hasErrors = false;
    },
    [continuouslyAbsent.rejected.type]: (state, action) => {
      state.continuouslyAbsent.loading = false;
      state.continuouslyAbsent.hasErrors = true;
      state.continuouslyAbsent.message = action.error.message;
    },

    //Low Performance Data
    [studentLowperformanceReport.pending.type]: (state) => {
      state.studentLowPerformanceData.loading = true;
    },
    [studentLowperformanceReport.fulfilled.type]: (state, action) => {
      state.studentLowPerformanceData.data = action.payload.data;
      state.studentLowPerformanceData.message = action.payload.message;
      state.studentLowPerformanceData.loading = false;
      state.studentLowPerformanceData.hasErrors = false;
    },
    [studentLowperformanceReport.rejected.type]: (state, action) => {
      state.studentLowPerformanceData.loading = false;
      state.studentLowPerformanceData.hasErrors = true;
      state.studentLowPerformanceData.message = action.error.message;
    },

    // get batchAndStudentCount data
    [facultyWiseBatchAndStudentCount.pending.type]: (state) => {
      state.batchAndStudentCount.loading = true;
    },
    [facultyWiseBatchAndStudentCount.fulfilled.type]: (state, action) => {
      state.batchAndStudentCount.data = action.payload.data;
      state.batchAndStudentCount.message = action.payload.message;
      state.batchAndStudentCount.loading = false;
      state.batchAndStudentCount.hasErrors = false;
    },
    [facultyWiseBatchAndStudentCount.rejected.type]: (state, action) => {
      state.batchAndStudentCount.loading = false;
      state.batchAndStudentCount.hasErrors = true;
      state.batchAndStudentCount.message = action.error.message;
    },

    //todayAbsentIsRemarksAdded
    [todayAbsentIsRemarksAdded.pending.type]: (state) => {
      state.todayAbsentIsRemarksAdded.loading = true;
    },
    [todayAbsentIsRemarksAdded.fulfilled.type]: (state, action) => {
      state.todayAbsentIsRemarksAdded.message = action.payload.message;
      state.todayAbsentIsRemarksAdded.hasErrors = action.payload.error;
      state.todayAbsentIsRemarksAdded.loading = false;
      state.todayAbsentIsRemarksAdded.hasErrors = false;
    },
    [todayAbsentIsRemarksAdded.rejected.type]: (state, action) => {
      state.todayAbsentIsRemarksAdded.loading = false;
      state.todayAbsentIsRemarksAdded.hasErrors = true;
      state.todayAbsentIsRemarksAdded.message = action.error.message;
    },
  },
});

// A selector
export const studentListSelector = (state: RootState) => state.studentList;

export const { clearRemoveMessage } = studentListSlice.actions;

// The reducer
export default studentListSlice.reducer;
